import { useEffect } from 'react';

import { userLatestTicketKey } from 'context/AuthProvider';
import { useAnalytics } from 'context/AnalyticsProvider';

export default function ApplicationStatusCheck({ location }) {
	const { ready, intercomReady } = useAnalytics();

	useEffect(() => {
		if (!ready || !intercomReady || !window.Intercom) return;

		// Get specific url parameter
		const urlParams = new URLSearchParams(location?.search);
		const openTicket = urlParams.get('sjekk-status');

		if (openTicket === '1') {
			const userLatestTicket = localStorage.getItem(userLatestTicketKey);

			// Get latest ticket id from local storage
			if (!userLatestTicket) {
				console.error(
					'No sponsorship ticket for user found in local storage'
				);
				return;
			}
			//Open intercom chat with ticket
			window.Intercom('showTicket', parseInt(userLatestTicket));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ready, intercomReady]);
}
