import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import MapComponent from './MapComponent';

export default function SponsorshipsMap({ title, ...props }) {
	const sponsorshipsData = useStaticQuery(sponsorshipQuery);
	const sponsorships = sponsorshipsData?.sponsorships?.nodes || [];

	if (!sponsorships || sponsorships.length === 0) return null;

	return (
		<div className="sponsorship-map" {...props}>
			<MapComponent
				sponsorships={sponsorships}
				headinglevel={props?.headinglevel}
			/>
		</div>
	);
}

/**
 * GraphQL query to get sponsorships from Contentful
 * @returns {Object} - The sponsorships data
 */
const sponsorshipQuery = graphql`
	query sponsorship {
		sponsorships: allContentfulInnholdReferanse(
			filter: { type: { eq: "Sponsorat" } }
			sort: { years: DESC }
		) {
			nodes {
				id
				title: tittel
				categories
				years
				place
				location {
					lat
					lon
				}
				images: bilder {
					file {
						url
					}
					width
					height
					description
				}
				text: tekst {
					raw
				}
				button {
					__typename
					... on Node {
						... on ContentfulKomponentKnapp {
							...KnappQuery
						}
					}
				}
			}
		}
	}
`;
