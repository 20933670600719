import React from 'react';
import styled, { css } from 'styled-components';

import { useAuth } from 'context/AuthProvider';
import Loading from 'components/Loading';
import BasicContent from 'parts/basic-content/BasicContent';
import AuthButtons from 'components/authentication/AuthButtons';
import AuthInformation from 'components/authentication/AuthInformation';

const Wrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 60px;
	${p =>
		p.theme.media.mediumDown(css`
			flex-direction: column;
			gap: 45px;
		`)}
`;

const Main = styled.div`
	flex-grow: 1;
	${p =>
		p.theme.media.mediumDown(css`
			width: 100%;
			> div {
				margin-top: 0 !important;
			}
		`)}
	${p =>
		p.theme.media.large(css`
			max-width: calc(100% - 448px - 60px);
		`)}
	
	.typeform-widget > div {
		box-shadow: ${p => p.theme.utils.boxShadowHard};
		border-radius: ${p => p.theme.utils.borderRadius};
	}
	.fullscreen-btn {
		margin-top: 10px;
	}
`;

const SideBar = styled.div`
	max-width: 100%;
	width: 448px;
	position: sticky;
	top: 140px;
	${p =>
		p.theme.media.mediumDown(css`
			width: 100%;
		`)}
	&.authenticated {
		padding-top: ${p => p.theme.spacing.desktop.medium};
		${p =>
			p.theme.media.mediumDown(css`
				padding-top: 0;
			`)}
	}
	.component__basic-content {
		padding: 0;
		.medium-width {
			padding: 0;
			margin: 0;
		}
	}
`;

export default function ApplicationAuth({ box, redirect, children }) {
	const { loading: keycloakLoading, user, method, ...auth } = useAuth();

	const isAuthenticated =
		!keycloakLoading && auth?.isAuthenticated && user?.trackingId && method;

	if (keycloakLoading) return <Loading text="Laster" />;

	return (
		<Wrapper>
			<Main>
				{keycloakLoading ? (
					<Loading text="Laster" />
				) : isAuthenticated && children ? (
					children
				) : (
					<>
						<AuthButtons
							title="Først må vi vite hvem du er"
							redirect={redirect}
							headinglevel="h2"
							headinglevelclass="h3"
						/>
						<AuthInformation
							id="sponsorship-apply-popup"
							type="identification"
						/>
					</>
				)}
			</Main>
			<SideBar
				className={`${!isAuthenticated ? 'not-' : ''}authenticated`}>
				{box && <BasicContent {...box} spacing={{ bottom: 'none' }} />}
			</SideBar>
		</Wrapper>
	);
}
